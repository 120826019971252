import React from "react";
import styled from "styled-components";

import logo from "../assets/icons/horizontal-logo.svg";

const MainContainer = styled.div`
  width: 100%;
  max-width: 100%;
  height: 60px;
  display: flex;
  background: white;
  justify-content: space-between;
  padding: 0 30px;
  box-sizing: border-box;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 100;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.15);
  @media (max-width: 450px) {
    padding: 0 10px;
    justify-content: center;
  }
`;

const Logo = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  & img {
    width: 110px;
  }
  & h1 {
    font-family: "Roboto-Black";
    font-size: 20px;
    margin-left: 30px;
  }
  @media (max-width: 700px) {
    & h1 {
      display: none;
    }
  }
  @media (max-width: 450px) {
    & img {
      display: none;
    }
  }
`;

const RightContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & :last-child {
    margin-left: 30px;
  }
`;

const Register = styled.button`
  background: black;
  padding: 11px 22px;
  color: white;
  border-radius: 30px;
  text-align: center;
  font-size: 15px;
  font-family: "Roboto-Medium";
  border: none;
  outline: none;
  cursor: pointer;
`;

export default function Header() {
  return (
    <MainContainer>
      <Logo>
        <img className="logo-img" src={logo} alt="" />
      </Logo>
      <RightContainer>
        <Register
          onClick={() =>
            (window.location = "https://www.app.depot.center/login")
          }
        >
          Inicia sesión
        </Register>
        <Register
          onClick={() =>
            (window.location = "https://www.app.depot.center/register")
          }
        >
          Regístrate
        </Register>
      </RightContainer>
    </MainContainer>
  );
}
