import React from "react";
import styled from "styled-components";

const MainContainer = styled.div`
  display: table;
  color: white;
  border-radius: 100px;
  text-align: center;
  position: fixed;
  right: 20px;
  z-index: 20 !important;
  padding: 5px;
  background: #5590ff !important;
  & p {
    padding: 0 35px;
    font-family: "Roboto-Regular";
    font-size: 16px !important;
  }
`;

export default function Footer(props) {
  const layerData = [
    // {id:'daysToLaunch', style: {background: '#ee5958', bottom: "100px"}, text: "Faltan 30 dias"},
    // {id:'launchingText', style: {background: '#323946', bottom: "20px"}, text: ["🎉 Lanzamiento oficial 15/Diciembre/2020 🎉 "]}
  ];

  const layers = layerData.map((element) => (
    <MainContainer key={element.id} style={element.style}>
      <div>
        <p>{element.text}</p>
      </div>
    </MainContainer>
  ));

  return layers;
}
