import React from "react";
import styled from "styled-components";

import Features from "./Feature";

// Features images
import feature8 from "../assets/images/feature_8.svg";
import feature9 from "../assets/images/feature_9.svg";
import feature10 from "../assets/images/feature_10.svg";

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const Text = styled.p`
  max-width: 750px;
  text-align: center;
  font-size: ${(props) => {
    if (props.title) {
      return "20px";
    } else {
      return "17px";
    }
  }};
  font-family: ${(props) => {
    if (props.title) {
      return "Roboto-Black";
    } else {
      return "Roboto-Regular";
    }
  }};
  background: ${(props) => {
    if (props.title) {
      return "rgba(0,0,0,.1)";
    } else {
      return null;
    }
  }};
  border-radius: 100px;
  padding: 10px 25px;
`;

export default function Products() {
  const products = [
    {
      id: "start-right-now",
      title: "DIGITALÍZATE EN SOLO 10 MINUTOS",
      image: feature10,
      left: false,
      ourPromise: true,
      text: "No necesitas conocimientos técnicos o grandes cantidades de tiempo para digitalizar tu negocio, invierte solo 10 minutos y empezarás a disfrutar de los beneficios que Depot tiene para ti.",
    },
    {
      id: "inventory",
      title: "15 DÍAS DE PRUEBA",
      image: feature9,
      left: true,
      ourPromise: true,
      text: "Usa nuestros servicios 15 días totalmente gratis, sin ingresar ningun dato bancario o utiliza nuestra versión gratuita de por vida.",
    },
    {
      id: "warranty-promise",
      title: "2 MESES DE GARANTÍA",
      image: feature8,
      left: false,
      ourPromise: true,
      text: "Satisfacción garantizada, de lo contrario durante los primeros dos meses pide tu reembolso y te devolvemos tu dinero.",
    },
  ];
  return (
    <MainContainer>
      <Text title>NUESTRA PROMESA</Text>
      <Features data={products}></Features>
    </MainContainer>
  );
}
