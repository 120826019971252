import React, { useEffect, useState } from "react";
import styled from "styled-components";

// icons
import arrow from "../assets/icons/down-arrow.svg";
import available from "../assets/icons/available.svg";
import { Fragment } from "react";

const MainContainer = styled.div`
  padding-top: 70px;
  padding-bottom: 70px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MainTitle = styled.h2`
  font-family: "Roboto-Bold";
  font-size: 20px;
  margin-bottom: 140px;
`;

const Title = styled.h2`
  font-family: "Roboto-Bold";
  font-size: 20px;
  margin-bottom: 40px;
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  > div:nth-child(n + 2) {
    border-width: 1px 0 0;
    border-color: #99a7bf;
    border-style: solid;
    box-sizing: border-box;
    > div > p {
      font-size: 14px;
      display: flex;
      align-items: center;
      height: 28px;
      margin: 10px 0 10px 40px;
      &:first-child {
        font-size: 15px;
        font-weight: 500;
        margin-top: 30px;
      }
      &:last-child {
        margin-bottom: 20px;
      }
      @media (max-width: 1024px) {
        margin: 0;
      }
    }
  }
  > div:last-child {
    max-width: 100%;
    border-width: 0;
    justify-content: center;
  }
`;

const HeaderContainerMobile = styled.div`
  width: 100%;
  & p {
    text-align: center;
  }
  & .planSelected {
    width: 80%;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid #99a7bf;
    padding: 50px 0;
    & .name {
      font-family: "Roboto-Bold";
      font-size: 20px;
      padding: 15px 0;
    }
    & .price {
      font-size: 21px;
      font-family: Roboto-Medium;
      color: rgb(85, 144, 255);
      padding: 15px 0;
    }
  }
`;

const PlanFeature = styled.p`
  font-family: "Roboto-Regular";
  display: flex;
  align-items: center;
  height: 28px;
  margin: 10px 10px 10px 40px;
`;

const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  > div:nth-child(2) {
    border-width: ${(props) => (props.lastRow ? "0" : "0 1px")};
    border-color: #99a7bf;
    border-style: solid;
    box-sizing: border-box;
  }
  > div:nth-child(n + 2) {
    box-sizing: border-box;
    padding-top: ${(props) => (props.lastRow ? "64px" : "0")};
    align-items: ${(props) => (props.lastRow ? "center" : "stretch")};
  }
  > div:first-child {
    text-align: left;
  }
`;

const TableCell = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 350px;
  @media (max-width: 1050px) {
    width: 330px;
  }
  > p > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const SectionTitle = styled.p`
  font-family: "Roboto-Medium";
  font-size: 15px;
  margin: 26px 0 0 40px;
`;

const Button = styled.button`
  width: 225px;
  height: 56px;
  border-radius: 37px;
  background-color: #5590ff;
  font-family: "Roboto-Medium";
`;

const PlanDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > p:nth-child(2) {
    font-size: 21px;
    font-family: "Roboto-Medium";
    color: #5590ff;
  }
  > p:first-of-type {
    margin-bottom: 32px;
  }
  > p:last-of-type {
    margin-bottom: 64px;
  }
`;

const TitleTableFloat = styled.div`
  position: sticky;
  top: 60px;
  z-index: 10;
  display: flex;
  flex-direction: row;
  width: 80%;
  height: 72px;
  margin: 15px auto;
  background-color: #fff;
`;

const NamePlan = styled.p`
  font-family: "Roboto-Bold";
  font-size: 15px;
  color: ${(props) => (props.isSelected ? "#5590ff" : "#000")};
  width: ${(props) => (props.percent ? `${props.percent}%` : "auto")};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Collapsible = styled.div`
  padding: 10px;
  box-sizing: border-box;
  width: 80%;
  > div:first-child {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    > img {
      width: 24px;
      height: 24px;
      transform: ${(props) =>
        props.isOpen ? "rotate(0deg)" : "rotate(180deg)"};
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  > div:last-child {
    margin-right: 7px;
  }
  > div > p {
    margin: 10px 0;
  }
`;

const ContainerValue = styled.div`
  height: 28px;
  width: 100%;
  margin: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  & figure {
    width: 22px;
    & img {
      width: 100%;
    }
  }
`;

const Circle = styled.div`
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #323946;
  margin: 5.5px 0;
`;

export default function Pricing() {
  const sistems = [
    {
      name: "SISTEMA DE INVENTARIOS",
      benefit: [
        { name: "Usuarios ilimitados", isGranted: [false, true] },
        { name: "Articulos ilimitados", isGranted: [true, true] },
        { name: "Bodegas ilimitadas", isGranted: [true, true] },
        { name: "Movimientos ilimitados", isGranted: [true, true] },
        { name: "Alertas de stock bajo", isGranted: [true, true] },
        { name: "Artículos compuestos", isGranted: [true, true] },
        { name: "Artículos ficticios", isGranted: [true, true] },
        {
          name: "Creación de categorías en artículos",
          isGranted: [true, true],
        },
        {
          name: "Contabilidad de stock en múltiples ubicaciones",
          isGranted: [true, true],
        },
        { name: "Roles de colaboradores", isGranted: [true, true] },
      ],
    },
    {
      name: "SISTEMA DE VENTAS",
      benefit: [
        { name: "Puntos de venta ilimitados", isGranted: [true, true] },
        {
          name: "Creación de promociones y descuentos",
          isGranted: [true, true],
        },
        {
          name: "Aplicación disponible en celulares",
          isGranted: [true, true],
        },
      ],
    },
  ];

  const plans = [
    {
      type: "GRATIS",
      description: "Precio de por vida",
      price: 0,
    },
    {
      type: "PREMIUM",
      description:
        "Precio a partir de 2 usuarios",
      price: 99,
    },
  ];
  const [showMenuItems, setShowItems] = useState(() => {
    let arr = [];
    for (var i = 0; i < plans.length; i++) {
      arr.push(false);
    }
    return arr;
  });

  const [planSelected, setPlanSelected] = useState(-1);
  useEffect(() => {
    const listener = (event) => {
      window.innerWidth <= 1024 ? setPlanSelected(0) : setPlanSelected(-1);
    };
    window.innerWidth <= 1024 ? setPlanSelected(0) : setPlanSelected(-1);
    window.addEventListener("resize", listener);
  }, []);

  useEffect(() => {}, [planSelected, sistems]);

  const showTable = [
    planSelected === -1 ? (
      <TableRow key={`unique0`}>
        <TableCell>
          <SectionTitle>PLANES</SectionTitle>
        </TableCell>
        {plans.map((e, i) => (
          <TableCell key={i}>
            <Title>{e.type}</Title>
            <PlanDescription>
              <p>{e.description}</p>
              <p>${e.price} MXN</p>
              <p>{!i ? "Exclusivo a un único usuario" : "Por usuario al mes"}</p>
            </PlanDescription>
          </TableCell>
        ))}
      </TableRow>
    ) : (
      <HeaderContainerMobile>
        <TitleTableFloat key={`unique0`}>
          {plans.map((e, i) => (
            <NamePlan
              isSelected={planSelected === i}
              percent={100 / plans.length}
              key={i}
              onClick={() => setPlanSelected(i)}
            >
              {e.type}
            </NamePlan>
          ))}
        </TitleTableFloat>
        <div className="planSelected">
          <p className="name">{plans[planSelected].type}</p>
          <p className="desc">{plans[planSelected].description}</p>
          <p className="price">${plans[planSelected].price} MXN</p>
        </div>
      </HeaderContainerMobile>
    ),
  ];

  sistems.map((e, i) =>
    showTable.push(
      planSelected === -1 ? (
        <TableRow key={i}>
          <TableCell>
            <SectionTitle>{e.name}</SectionTitle>
            {e.benefit.map((benefit, ind) => (
              <p key={ind}>{benefit.name}</p>
            ))}
          </TableCell>
          <TableCell>
            <SectionTitle></SectionTitle>
            {e.benefit.map((benefit, ind) => (
              <ContainerValue>
                {benefit.isGranted[0] ? (
                  <figure>
                    <img src={available} alt="Available attribute"></img>
                  </figure>
                ) : (
                  <Circle />
                )}{" "}
              </ContainerValue>
            ))}
          </TableCell>
          <TableCell>
            <SectionTitle></SectionTitle>
            {e.benefit.map((benefit, ind) => (
              <ContainerValue>
                {benefit.isGranted[1] ? (
                  <figure>
                    <img src={available} alt="Available attribute"></img>
                  </figure>
                ) : (
                  <Circle />
                )}{" "}
              </ContainerValue>
            ))}
          </TableCell>
        </TableRow>
      ) : (
        <>
          <Collapsible key={i} isOpen={!showMenuItems[i]}>
            <Content>
              <div>
                {" "}
                {e.benefit.map((benefit, ind) => (
                  <PlanFeature>{benefit.name}</PlanFeature>
                ))}{" "}
              </div>
              <div>
                {" "}
                {e.benefit.map((benefit, ind) => (
                  <ContainerValue>
                    {benefit.isGranted[planSelected] ? (
                      <figure>
                        <img src={available} alt="Available attribute"></img>
                      </figure>
                    ) : (
                      <Circle />
                    )}{" "}
                  </ContainerValue>
                ))}
              </div>
            </Content>
          </Collapsible>
        </>
      )
    )
  );

  return (
    <MainContainer>
      <MainTitle>PRECIOS</MainTitle>
      <TableContainer>
        {showTable}
        {planSelected === -1 ? (
          <TableRow lastRow>
            <TableCell />
            <TableCell>
              <Button
                onClick={() =>
                  (window.location.href = "https://www.app.depot.center")
                }
              >
                Úsalo gratis
              </Button>
            </TableCell>
            <TableCell>
              <Button
                onClick={() =>
                  (window.location.href = "https://www.app.depot.center")
                }
              >
                Prueba 15 días gratis
              </Button>
            </TableCell>
          </TableRow>
        ) : (
          <TableRow lastRow>
            <TableCell />
            <TableCell>
              <Button
                onClick={() =>
                  (window.location.href = "https://www.app.depot.center")
                }
              >
                Úsalo ahora
              </Button>
            </TableCell>
            <TableCell />
          </TableRow>
        )}
      </TableContainer>
    </MainContainer>
  );
}
