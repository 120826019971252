import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Transition } from "react-transition-group";

import producto from "../assets/images/smartmockups.png";
import wave from "../assets/images/trazado.svg";

const MainContainer = styled.div`
  position: relative;
  width: 100%;
  transition: all 0.5s ease;
  opacity: ${({ state }) => (state === "entered" ? 1 : 0)};
  transform: translateY(
    ${({ state }) => (state === "entering" || state === "entered" ? 0 : -500)}px
  );
  background: linear-gradient(
    180deg,
    #2bde73 0%,
    #3ebbb1 25%,
    #5590ff 45%,
    #5590ff 100%
  );
`;

const Title = styled.h1`
  margin: 0;
  margin: 50px 0 60px 0;
  text-align: center;
  padding-top: 115px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 1);
  font-family: "Roboto-Bold";
  font-size: 40px;
  & span {
    font-size: 21px;
  }
`;

const MainImage = styled.figure`
  position: relative;
  z-index: 2;
  width: 60%;
  margin: 0 auto;
  user-select: none;
  @media (max-width: 900px) {
    width: 80%;
  }
  @media (max-width: 700px) {
    width: 90%;
  }
  & img {
    width: 100%;
  }
`;

const Wave = styled.figure`
  position: absolute;
  z-index: 1;
  width: 100%;
  margin: 0;
  bottom: -10px;
  & img {
    width: 101%;
  }
  overflow: hidden;
`;

export default function Banner() {
  const [appear, setAppearState] = useState(false);
  const [firstExecution, setExecutionState] = useState(true);

  useEffect(() => {
    // Animate here
    if (firstExecution) {
      setAppearState(true);
      setExecutionState(false);
    }
  }, [firstExecution]);

  return (
    <div>
      <Transition in={appear} timeout={400}>
        {(state) => {
          return (
            <MainContainer state={state}>
              <Title>
                El sistema de Inventario y Punto de Venta
                <br />
                <span>más avanzado de México </span>
              </Title>
              <MainImage>
                <img src={producto} alt="Depot System"></img>
              </MainImage>
              <Wave>
                <img src={wave} alt="Wave background"></img>
              </Wave>
            </MainContainer>
          );
        }}
      </Transition>
    </div>
  );
}
