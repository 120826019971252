import React from "react";
import styled from "styled-components";

import Features from "./Feature";

// Product Images
import feature1 from "../assets/images/feature12.png";
import feature2 from "../assets/images/feature13.png";
import feature3 from "../assets/images/feature14.png";

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export default function Products() {
  const products = [
    {
      id: "inventory",
      title: "TEN EL CONTROL DE TU NEGOCIO",
      image: feature2,
      left: true,
      text: "Lleva un control exacto de todas las entradas y salidas de productos. Sin importar la cantidad de artículos, sucursales, bodegas, plantas de producción o empleados que dispongas, todo estará organizado de forma automática con tu sistema de inventario.",
    },
    {
      id: "sales-point",
      title: "VENDE PROFESIONALMENTE",
      image: feature3,
      left: false,
      text: "Agiliza los procesos de cobro en cada sucursal que dispongas, lleva conteo de capital en caja, mide la efectividad de tus vendedores y mucho más con tu sistema de punto de venta.",
    },
    {
      id: "online-store",
      title: "COLABORA SIN LÍMITES",
      image: feature1,
      left: true,
      text: "Olvídate del árduo trabajo que conlleva el administrar tu negocio, registra tus colaboradores, asigna roles y administra tu negocio sin preocupaciones.",
    },
  ];

  return (
    <MainContainer>
      <Features data={products}></Features>
    </MainContainer>
  );
}
