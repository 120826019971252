import React from "react";
import styled from "styled-components";

import facebook from "../assets/images/facebook.svg";
import linkedin from "../assets/images/linkedin.svg";

const MainContainer = styled.div`
  width: 100%;
  min-height: 80px;
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  box-sizing: border-box;
  & p {
    color: #ffffff;
    margin: 0;
    font-size: 12px;
    font-family: "Roboto-Medium";
  }
  & img {
    opacity: 0.9;
    width: 20px;
    margin-left: 20px;
  }
  & div {
    width: 150px;
  }
  @media (max-width: 680px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & div {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 15px auto;
    }
  }
`;

export default function Footer(props) {
  return (
    <MainContainer>
      <div>
        <a href="https://www.facebook.com/wallavimx" target="_new">
          <img src={facebook} alt="Facebook page" />
        </a>
        <a href="https://www.linkedin.com/company/wallavi" target="_new">
          <img src={linkedin} alt="Linkedin page" />
        </a>
      </div>
      <p>Wallavi S.A. De C.V. 2022. Todos los derechos reservados.</p>
      <div></div>
    </MainContainer>
  );
}
