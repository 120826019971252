import React from "react";
import styled from "styled-components";

const MainContainer = styled.div`
  margin: 120px auto;
  width: 96%;
  max-width: 800px;
  & h2 {
    font-size: 20px;
    font-family: "Roboto-Bold";
    text-align: center;
  }
`;

const Review = styled.p`
  text-align: center;
  font-size: 21px;
  margin: 30px 0;
`;

const Writter = styled.p`
  text-align: right;
  font-family: "Roboto-Medium";
  margin-top: 20px;
`;

export default function Reviews(props) {
  return (
    <MainContainer>
      <h2>RESEÑAS</h2>
      <Review>
        "Implementar Depot en nuestro negocio ha simplificado nuestras labores
        cotidianas, llevar el control del negocio es más fácil que nunca"
      </Review>
      <Writter>Oscar Parra - Don Parrafa</Writter>
    </MainContainer>
  );
}
