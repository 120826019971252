import React from "react";
import styled from "styled-components";
import Slider from "react-slick";

const MainContainer = styled.div`
  height: 300px;
  background-color: #f5f5f5;
  width: 100%;
  margin: 60px auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
  @media (max-width: 700px) {
    margin-top: 170px;
    padding: 0 !important;
  }
`;

const ContainerForWebsite = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  @media (max-width: 700px) {
    display: none;
  }
`;

const BrandForWebsite = styled.figure`
  width: 12%;
  margin: 0;
  & img {
    margin: 0;
    width: 100%;
  }
`;

const ContainerForMobile = styled.div`
  display: none;
  width: 100%;
  @media (max-width: 700px) {
    display: block;
    width: 100%;
  }
`;

const BrandForMobile = styled.div`
  display: flex !important;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  & img {
    object-fit: cover;
  }
`;

export default function Brands(props) {
  const brands = [
    { image: "./brands/donparrafa.png" },
    { image: "./brands/nina.png" },
    { image: "./brands/mck.png" },
    { image: "./brands/hm.png" },
    { image: "./brands/tablitas.png" },
    { image: "./brands/recubric.png" },
  ];

  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    dots: true,
  };

  return (
    <MainContainer>
      <ContainerForWebsite>
        {brands.map((element, index) => (
          <BrandForWebsite key={index}>
            <img src={element.image} alt="" />
          </BrandForWebsite>
        ))}
      </ContainerForWebsite>
      <ContainerForMobile>
        <Slider {...settings}>
          {brands.map((element, index) => (
            <BrandForMobile key={index}>
              <img src={element.image} alt="" />
            </BrandForMobile>
          ))}
        </Slider>
      </ContainerForMobile>
    </MainContainer>
  );
}
