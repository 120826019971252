import React from "react";
import styled from "styled-components";

const MainContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 80px auto;
  width: 96%;
  max-width: ${(props) => (props.ourPromise ? "850px" : "1024px")};
  justify-content: space-around;
  & h2 {
    font-size: 20px;
    font-family: "Roboto-Bold";
  }
  & p {
    font-size: 17px;
  }
  @media (max-width: 700px) {
    display: block;
    position: relative;
    margin: ${(props) => (props.ourPromise ? "0 auto" : "50px auto")};
    margin-top: ${(props) =>
      props.right && props.ourPromise ? "-80px" : null};
  }
`;

const ImageContainer = styled.div`
  width: ${(props) => (props.ourPromise ? "170px" : "270px")};
  height: ${(props) => (props.ourPromise ? "170px" : "270px")};
  border-radius: 100%;
  background-size: contain;
  background-position: center;
  box-shadow: ${(props) =>
    props.ourPromise ? null : "0px 5px 15px 1px rgba(0,0,0,0.10)"};
  flex-shrink: 0;
  @media (max-width: 700px) {
    margin: 0 auto;
    position: ${(props) => (props.right ? "absolute" : null)};
    top: ${(props) => (props.right ? (props.ourPromise ? "110px" : 0) : null)};
    left: ${(props) => (props.right ? 0 : null)};
    right: ${(props) => (props.right ? 0 : null)};
  }
`;

const TextContainer = styled.div`
  width: 550px;
  max-width: 100%;
  margin: 0 20px;
  @media (max-width: 700px) {
    text-align: center;
    margin: 30px auto;
    width: 94%;
    & h2 {
      text-align: center;
    }
    padding-top: ${(props) => (props.right ? "260px" : null)};
  }
`;

export default function Feature(props) {
  const features = props.data?.map((element) => {
    if (element.left) {
      return (
        <LeftFeature
          key={element.id}
          title={element.title}
          image={element.image}
          text={element.text}
          ourPromise={element.ourPromise}
        />
      );
    } else {
      return (
        <RightFeature
          key={element.id}
          title={element.title}
          image={element.image}
          text={element.text}
          ourPromise={element.ourPromise}
        />
      );
    }
  });

  return features;
}

const LeftFeature = (props) => {
  return (
    <MainContainer ourPromise={props.ourPromise}>
      <ImageContainer
        style={{ backgroundImage: `url("${props.image}")` }}
        ourPromise={props.ourPromise}
      ></ImageContainer>
      <TextContainer>
        <h2>{props.title}</h2>
        <p>{props.text}</p>
      </TextContainer>
    </MainContainer>
  );
};

const RightFeature = (props) => {
  return (
    <MainContainer right ourPromise={props.ourPromise}>
      <TextContainer right>
        <h2>{props.title}</h2>
        <p>{props.text}</p>
      </TextContainer>
      <ImageContainer
        right
        style={{ backgroundImage: `url("${props.image}")` }}
        ourPromise={props.ourPromise}
      ></ImageContainer>
    </MainContainer>
  );
};
