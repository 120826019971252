import React from "react";
import styled from "styled-components";

const MainContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  z-index: 25;
`;

const CenteredContainer = styled.div`
  position: fixed;
  width: 400px;
  border-radius: 10px;
  left: 50%;
  top: 50%;
  background: white;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px;
`;

const Title = styled.p`
  font-size: 15px;
  font-family: "Roboto-Medium";
  text-align: center;
  margin-bottom: 20px;
`;

const Button = styled.button`
  border-radius: 100px;
  background: #5590ff;
  outline: none;
  border: none;
  color: white;
  padding: 10px 15px;
  font-family: "Roboto-Medium";
  font-size: 15px;
  cursor: pointer;
`;

export default function SignUpSuccessMessage(props) {
  return (
    <MainContainer>
      <CenteredContainer>
        <Title>
          <span role="img" aria-label="Congrats">
            🎉
          </span>{" "}
          Tu registro ha sido exitoso{" "}
          <span role="img" aria-label="Congrats">
            🎉
          </span>
          <br></br>
          <br></br>¡Pronto podrás disfrutar de los beneficios que Depot tiene
          para tu negocio!
        </Title>
        <Button onClick={() => props.close()}>Aceptar</Button>
      </CenteredContainer>
    </MainContainer>
  );
}
