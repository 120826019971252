import React, { useState } from "react";
import styled from "styled-components";

import Header from "./components/Header.js";
import Banner from "./components/Banner.js";
import Layers from "./components/Layers.js";
import Products from "./components/Products.js";
import OurPromise from "./components/OurPromise.js";
import Brands from "./components/Brands.js";
import Pricing from "./components/Pricing.js";
import Reviews from "./components/Reviews.js";
import Footer from "./components/Footer.js";
import Partners from "./components/Partners.js";
import SignUpSuccessMessage from "./components/SignUpSuccessMessage";

const Description = styled.div`
  width: 100%;
  padding: 120px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Text = styled.p`
  margin-top: 30px;
  max-width: 750px;
  text-align: center;
  font-size: 30px;
  font-family: "Roboto-Regular";
  border-radius: 100px;
  padding: 10px 25px;
`;

function App() {
  const [successMessageIsActive, setSuccessMessageState] = useState(
    window.location.href.includes("signup=success")
  );

  return (
    <div className="App">
      {successMessageIsActive && (
        <SignUpSuccessMessage
          close={() => setSuccessMessageState(false)}
        ></SignUpSuccessMessage>
      )}
      <Header />
      <Banner />
      <Layers></Layers>
      <Description>
        <Text>
          Llevar el control de tu negocio dejará de ser un problema, reduce
          mermas por exceso o falta de inventario, aumenta la efectividad de tus
          empleados, mejora la experiencia de venta y mucho más con Depot.
        </Text>
      </Description>
      <Products />
      <OurPromise />
      <Brands />
      <Pricing />
      <Reviews />
      <Partners />
      <Footer />
    </div>
  );
}

export default App;
