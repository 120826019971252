import React from "react";
import styled from "styled-components";

import cdn from "../assets/images/cdn.svg";

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 120px auto;
  width: 96%;
  max-width: 800px;
  & h2 {
    font-size: 20px;
    font-family: "Roboto-Bold";
    text-align: center;
  }
`;

const PartnerLogo = styled.figure`
  width: 190px;
  margin: 70px auto;
  img {
    width: 100%;
  }
`;

const Promo = styled.p`
  width: 600px;
  max-width: 100%;
  text-align: center;
  font-size: 21px;
  margin: 0px auto;
  margin-bottom: 80px;
  span {
    font-family: "Roboto-Medium";
  }
`;

const Steps = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const Step = styled.div`
  margin: 10px;
  width: 250px;
  height: 210px;
  box-sizing: border-box;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
`;

const StepNumber = styled.p`
  display: flex;
  align-items: center;
  color: #5590ff;
  font-family: "Roboto-Bold";
  font-size: 15px;
  margin-bottom: 40px;
  span {
    color: white;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #5590ff;
    border-radius: 100px;
    margin-right: 10px;
  }
`;

const StepDescription = styled.div`
  font-family: "Roboto-Regular";
  font-size: 15px;
  color: #3b3b3b;
`;

const Register = styled.button`
  width: 225px;
  height: 56px;
  border-radius: 37px;
  background-color: #5590ff;
  font-family: "Roboto-Medium";
  margin-top: 70px;
`;

export default function Reviews(props) {
  return (
    <MainContainer>
      <h2>ALIANZAS</h2>
      <PartnerLogo>
        <img src={cdn} alt="Partner brand" />
      </PartnerLogo>
      <Promo>
        Si eres cliente de CDN,{" "}
        <span>obtén $950.00 MXN de bono de bienvenida</span> para que los
        utilices en Depot.
      </Promo>
      <Steps>
        <Step>
          <StepNumber>
            <span>1</span> Regístrate
          </StepNumber>
          <StepDescription>
            Ingresar la información de tu negocio solo te tomará 2 minutos.
          </StepDescription>
        </Step>
        <Step>
          <StepNumber>
            <span>2</span> Úsalo gratis
          </StepNumber>
          <StepDescription>
            Tienes 15 días para utilizar la plataforma PREMIUM totalmente sin
            costo.
          </StepDescription>
        </Step>
        <Step>
          <StepNumber>
            <span>3</span> Recibe tu bono
          </StepNumber>
          <StepDescription>
            Validaremos tus datos con CDN. En caso de ser cliente de CDN te
            depositaremos tu bono (puede demorar hasta 5 días).
          </StepDescription>
        </Step>
      </Steps>
      <Register
        onClick={() => (window.location.href = "https://www.app.depot.center")}
      >
        Reclamar bono
      </Register>
    </MainContainer>
  );
}
